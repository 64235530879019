((angular) => {
  angular
    .module('dcApp')
    .factory('loginService', ['$http', 'apiUrl', ($http, apiUrl) => {
        return {
          getLocation: async () => {
            const loc = await $http.get(apiUrl + '/location');
            return loc.data;
          },
          authenticate: (username, password) => {
            return new Promise((resolve) => {
              $http
                .post(apiUrl + '/authenticate', { username: username, password: password })
                .then(function(result) {
                  // Success
                  resolve(true);
                })
                .catch(function(err) {
                  // Failed
                  resolve(false);
                });
            });
          }
        };
      }
    ])
    .controller('loginCtrl', ['$scope', 'loginService', ($scope, service) => {
      $scope.username = '';
      $scope.password = '';
      service
        .getLocation()
        .then(result => {
          $scope.location = result;
          $scope.$apply();
        });

      $scope.execute = async () => {
        const result = await service.authenticate($scope.username, $scope.password);
        if(result)
          window.location.href = '/';
        else {
          $scope.errorText = 'Authentikacija nije uspjela! Pokusajte ponovno...';
          $scope.password = '';
        }
      };
    }]);
})(window.angular);
